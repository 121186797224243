import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import ContactForm from '../../core/sysext/Boutique/containers/Contact/components/Form';
import ContactList from '../../core/sysext/Boutique/containers/Contact/components/List';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';

const ContactsPage: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;

  return (
    <Backoffice location={location}>
      {star ? (
        <ContactForm docId={star} {...props} />
      ) : (
        <ContactList {...props} />
      )}
    </Backoffice>
  );
};

export default ContactsPage;
