import { ContactData } from '@innedit/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import requireBoutique, {
  BoutiqueProps,
} from '../../../../../../utils/requireBoutique';
import CMSView from '../../../../../CMS/components/View/index';
import List from '../../../../../CMS/containers/Boutique/components/new/List';
import contact from '../../params.json';
import Item from './Item';

const ContactList: FC<BoutiqueProps> = ({ boutique }) => {
  const { t } = useTranslation();

  return (
    <CMSView>
      <List
        item={Item}
        itemPathnamePrefix="/boutique/contacts/"
        model={
          new ContactData({
            boutique,
            orderDirection: 'asc',
            orderField: 'name',
            params: contact,
          })
        }
        searchShow
        title={t('contacts')}
      />
    </CMSView>
  );
};

export default requireBoutique(ContactList);

// addActionBarToRender() {
//   const menuClassNames = ['menu-annexe'];
//   menuClassNames.push(this.state.openMenu ? 'open' : 'close');
//
//   return (
//     <ActionBar className="action-bar is-clearfix">
//       <ul>
//         <li className="navbar-item">
//           <Button icon="fa-plus" onClick={this.handleCreate} />
//         </li>
//       </ul>
//
//       <div className={menuClassNames.join(' ')}>
//         <Button
//           className="open-menu"
//           icon="fa-ellipsis-v"
//           onClick={this.handleOpenMenuOnMobile}
//         />
//         <div className="menu-annexe-content">
//           <ul>
//             <li className="navbar-item">
//               <select
//                 data-key="subscribedToNewsletter"
//                 onChange={this.handleChangeCustomWheres}
//                 value={this.state.custom.wheres.subscribedToNewsletter}
//               >
//                 <option value="">Tous les contacts</option>
//                 <option value="true">Inscrits</option>
//                 <option value="false">Non Inscrits</option>
//               </select>
//             </li>
//           </ul>
//         </div>
//       </div>
//
//       <ul className="border-left navbar-right">
//         <li className="navbar-item search">
//           <input
//             onChange={this.handleOnChangeSearch}
//             onKeyPress={this.handleOnKeyPressSearch}
//             placeholder="Rechercher"
//             type="text"
//             value={this.state.q}
//           />
//           {this.state.q && (
//             <button
//               className="empty"
//               onClick={this.handleEmptySearch}
//               type="button"
//             >
//               <span className="icon" />
//               <span className="text">Vider</span>
//             </button>
//           )}
//         </li>
//       </ul>
//     </ActionBar>
//   );
// }
