import { ContactType, FirebaseFirestore } from '@innedit/innedit';
import React, { FC, useEffect, useState } from 'react';

import Icon from '../../../../../../components/Icon';
import IconNewspaper from '../../../../../../icons/Newspaper';
import Item from '../../../../../CMS/containers/Boutique/components/new/Item';
import { ListItemProps } from '../../../../../CMS/containers/Boutique/components/new/props';

const ListItem: FC<ListItemProps<ContactType>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] =
    useState<FirebaseFirestore.DocumentSnapshot<ContactType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const data = doc.data() as ContactType;

  if (!data) {
    return null;
  }

  const name = [];
  if (data.firstName) {
    if (data.lastName) {
      name.push(`${data.firstName} ${data.lastName}`);
    } else {
      name.push(data.firstName);
    }
  } else if (data.lastName) {
    name.push(data.lastName);
  }

  return (
    <Item
      contentClassName="pl-3 flex flex-col"
      doc={doc}
      index={index}
      onClick={onClick}
    >
      <strong className="name">{name.join(' - ')}</strong>
      {data.email && (
        <div className="flex  items-center space-x-3">
          {data.subscribedToNewsletter && <Icon value={IconNewspaper} />}
          <span>{data.email}</span>
        </div>
      )}
      {data.phone && <span>{data.phone}</span>}
    </Item>
  );
};

export default ListItem;
